import FlushingTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-redness/flushing/FlushingTemplate';

import { FLUSH_DURATION, FLUSH_LOCATION, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/Redness/Flushing/FlushingTemplate'
};

const createStory = props => () => ({
  components: { FlushingTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <flushing-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  flushes: 'yes',
  hotFlushes: 'yes',
  flushesDuration: FLUSH_DURATION.FEW_YEARS,
  flushLocations: [FLUSH_LOCATION.NECK],
  gender: GENDER.FEMALE
});

export const Menopause = createStory({
  flushes: 'yes',
  showMenopauseQuestion: true,
  hotFlushes: 'yes',
  flushesDuration: FLUSH_DURATION.FEW_YEARS,
  flushLocations: [FLUSH_LOCATION.NECK],
  gender: GENDER.FEMALE
});

export const NoMenopause = createStory({
  flushes: 'no',
  showMenopauseQuestion: false,
  gender: GENDER.FEMALE
});
